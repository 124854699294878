import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import { makeStyles } from "@material-ui/core/styles"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Grid, Typography } from "@material-ui/core"
import ToolCard from "../tools/toolCard"
import SEO from "../header/seo"
import TagDisplay from "../sidebar/tagDisplay"
import Hyphenated from "react-hyphen"
import de from "hyphenated-de"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("md")]: {
      padding: "100px 20px 50px",
    },
  },
  containerSmall: {
    paddingTop: "50px",
  },
  sidebar: {
    background: "#0028A5",
    borderBottom: "6px solid #DC6027",
    borderRadius: "5px",
    color: theme.palette.common.white,
    margin: "100px 20px 0",
    padding: "30px 30px 30px 30px",
    textAlign: "right",
    [theme.breakpoints.up("md")]: {
      float: "right",
      width: "180px",
      margin: "100px 20px 0 0",
      borderRadius: "5px 0 5px 0",
    },
    "& > div": {
      width: "100%",
      float: "none",
    },
  },
  title: { marginBottom: "1.5rem", color: "#0028A5" },
  subtitle: { marginBottom: "2rem" },
  bodyText: { marginBottom: "2rem" },
  buttonMain: { marginRight: "20px" },
  buttonSecondary: {},
  toolAction: {},
}))

const ToolByCategoryPage = props => {
  const { data, intl } = props
  const classes = useStyles()
  const category = data.markdownRemark
  const tools = data.allMarkdownRemark
  const cats = data.cats.edges.map(cats => {
    return (
      <Link to={`${cats.node.fields.slug}`}>{cats.node.frontmatter.title}</Link>
    )
  })

  return (
    <Layout>
      <SEO title={category.frontmatter.title} />
      <Grid container>
        <Grid item xs={12} md={9} className={classes.container}>
          <Typography variant="h1" className={classes.title}>
            {category.frontmatter.title}
          </Typography>
          <Hyphenated language={intl.local}>
            <Typography
              className={classes.bodyText}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: category.html }}
            />
          </Hyphenated>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.sidebar}>
            <TagDisplay title="other-category" content={cats} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.container}>
            <Grid container spacing={3} className={classes.toolContainer}>
              {tools.edges.map(({ node }) => {
                return (
                  <Grid key={node.id} item xs={12} sm={6} lg={4}>
                    <ToolCard data={node} />
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default injectIntl(ToolByCategoryPage)

export const query = graphql`
  query($slug: String!, $language: String, $name: String) {
    markdownRemark(fields: { slug: { eq: $slug }, lang: { eq: $language } }) {
      html
      frontmatter {
        title
        slug
      }
      fields {
        slug
        lang
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { lang: { eq: $language } }
        frontmatter: { category: { eq: $name } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            costs
            licence
            link
          }
          fields {
            slug
            lang
          }
        }
      }
    }
    cats: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/categories/" }, lang: { eq: $language } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
          fields {
            slug
            lang
          }
        }
      }
    }
  }
`
